import React, { useState, useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useSpeechRecognitionContext } from "../hooks/useSpeechToText";
import * as THREE from "three";
import { Polly } from "aws-sdk";
import { Buffer } from "buffer";
import visemeMap from "../utils/visemeMaps";

const polly = new Polly({
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  },
  region: process.env.REACT_APP_REGION,
});

export function Avatar1(props) {
  const { nodes, materials } = useGLTF(
    "/models/66e41cf73403d6dd0ab4d8f7 (1).glb"
  );
  const {
    responseData,
    setIsSpeaking,
    isSpeaking,
    audioContextRef,
    audioSourceRef,
    setCues,
    cues,
    initializeAudioContext,
  } = useSpeechRecognitionContext();
  const startTimeRef = useRef(0);
  const [blink, setBlink] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [lastPlayedAudioUrl, setLastPlayedAudioUrl] = useState("");
  const smoothMorphTarget = true;
  const morphTargetSmoothing = 0.1;
  const [eyebrowRaise, setEyebrowRaise] = useState(0);
  const [handGesture, setHandGesture] = useState(0);
  const audioElementRef = useRef(null);

  const lerpMorphTarget = (target, value, speed = morphTargetSmoothing) => {
    const meshes = [
      nodes.Wolf3D_Head,
      nodes.Wolf3D_Teeth,
      nodes.Wolf3D_Eyes,
      nodes.Wolf3D_Tongue,
      nodes.Wolf3D_Body,
    ];

    meshes.forEach((mesh) => {
      if (mesh && mesh.morphTargetDictionary && mesh.morphTargetInfluences) {
        const index = mesh.morphTargetDictionary[target];
        if (index !== undefined) {
          mesh.morphTargetInfluences[index] = THREE.MathUtils.lerp(
            mesh.morphTargetInfluences[index],
            value,
            speed
          );
        }
      }
    });
  };

  useEffect(() => {
    let blinkTimeout;
    const nextBlink = () => {
      blinkTimeout = setTimeout(() => {
        setBlink(true);
        setTimeout(() => {
          setBlink(false);
          nextBlink();
        }, 200);
      }, THREE.MathUtils.randInt(1000, 5000));
    };
    nextBlink();
    return () => clearTimeout(blinkTimeout);
  }, []);

  useEffect(() => {
    if (!responseData) return;

    const ssmlText = `<speak>${responseData}</speak>`;

    const params = {
      TextType: "ssml",
      OutputFormat: "mp3",
      Text: ssmlText,
      VoiceId: "Brian",
    };

    polly.synthesizeSpeech(params, (err, data) => {
      if (err) {
        console.log("Error synthesizing speech:", err);
      } else {
        const audioContent = Buffer.from(data.AudioStream).toString("base64");
        const audioUrl = `data:audio/mp3;base64,${audioContent}`;
        setAudioUrl(audioUrl);
      }
    });

    const visemeParams = {
      OutputFormat: "json",
      SampleRate: "22050",
      Text: ssmlText,
      TextType: "ssml",
      VoiceId: "Brian",
      SpeechMarkTypes: ["viseme"],
    };

    polly.synthesizeSpeech(visemeParams, (err, data) => {
      if (err) {
        console.error("Error fetching viseme data:", err);
        return;
      }

      try {
        const jsonStr = Buffer.from(data.AudioStream).toString("utf8");
        const parsedJson = jsonStr
          .trim()
          .split("\n")
          .map((line) => {
            try {
              return JSON.parse(line);
            } catch {
              return null;
            }
          })
          .filter(Boolean);

        setCues(parsedJson);
        startTimeRef.current = performance.now() / 1000;
      } catch (error) {
        console.error("Error processing viseme data:", error);
      }
    });
  }, [responseData, setCues]);

  useEffect(() => {
    if (audioUrl && audioUrl !== lastPlayedAudioUrl) {
      const playAudio = async () => {
        try {
          initializeAudioContext();

          const response = await fetch(audioUrl);
          const arrayBuffer = await response.arrayBuffer();
          const audioBuffer = await audioContextRef.current.decodeAudioData(
            arrayBuffer
          );

          audioSourceRef.current = audioContextRef.current.createBufferSource();
          audioSourceRef.current.buffer = audioBuffer;
          audioSourceRef.current.connect(audioContextRef.current.destination);

          setIsSpeaking(true);
          audioSourceRef.current.start(0);

          audioSourceRef.current.onended = () => {
            setIsSpeaking(false);
            setAudioUrl("");
            setLastPlayedAudioUrl("");
            audioSourceRef.current = null;
          };

          setLastPlayedAudioUrl(audioUrl);
        } catch (error) {
          console.error("Error playing audio:", error);
          setIsSpeaking(false);
        }
      };

      playAudio();
    }
  }, [
    audioUrl,
    setIsSpeaking,
    lastPlayedAudioUrl,
    audioContextRef,
    audioSourceRef,
    initializeAudioContext,
  ]);

  useEffect(() => {
    const initAudio = () => {
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext ||
          window.webkitAudioContext)();
      }
      if (audioContextRef.current.state === "suspended") {
        audioContextRef.current.resume();
      }
    };

    const handleInteraction = () => {
      initAudio();
      document.removeEventListener("touchstart", handleInteraction);
      document.removeEventListener("click", handleInteraction);
    };

    document.addEventListener("touchstart", handleInteraction);
    document.addEventListener("click", handleInteraction);

    return () => {
      document.removeEventListener("touchstart", handleInteraction);
      document.removeEventListener("click", handleInteraction);
    };
  }, [audioContextRef]);

  useEffect(() => {
    return () => {
      setAudioUrl("");
      setLastPlayedAudioUrl("");
    };
  }, []);

  useFrame((state, delta) => {
    lerpMorphTarget("eyeBlinkLeft", blink ? 1 : 0, 0.5);
    lerpMorphTarget("eyeBlinkRight", blink ? 1 : 0, 0.5);

    const currentTime = performance.now() / 1000 - startTimeRef.current;

    Object.values(visemeMap)
      .flat()
      .forEach((morph) => {
        lerpMorphTarget(morph, 0, morphTargetSmoothing);
      });

    if (cues.length > 0) {
      const currentCue = cues.find(
        (cue) =>
          currentTime >= cue.time / 1000 && currentTime < cue.time / 1000 + 0.1
      );

      if (currentCue) {
        const morphTargets =
          visemeMap[currentCue.value.toLowerCase()] || visemeMap.sil;
        morphTargets.forEach((morph) => {
          lerpMorphTarget(
            morph,
            1,
            smoothMorphTarget ? morphTargetSmoothing : 1
          );
        });

        if (Math.random() < 0.05) {
          setEyebrowRaise(Math.random() * 0.3);
        }

        if (Math.random() < 0.02) {
          setHandGesture(Math.random());
        }
      }
    } else {
      visemeMap.sil.forEach((morph) => {
        lerpMorphTarget(morph, 1, smoothMorphTarget ? morphTargetSmoothing : 1);
      });
    }

    lerpMorphTarget("browInnerUp", eyebrowRaise, 0.9);

    lerpMorphTarget("rightHandThumb1", handGesture, 0.1);
    lerpMorphTarget("rightHandIndex1", handGesture, 0.1);
    lerpMorphTarget("leftHandThumb1", handGesture, 0.1);
    lerpMorphTarget("leftHandIndex1", handGesture, 0.1);
  });

  return (
    <group {...props} dispose={null}>
      <primitive object={nodes.Hips} />
      <skinnedMesh
        name="EyeLeft"
        geometry={nodes.EyeLeft.geometry}
        material={materials.Wolf3D_Eye}
        skeleton={nodes.EyeLeft.skeleton}
        morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
        morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
      />
      <skinnedMesh
        name="EyeRight"
        geometry={nodes.EyeRight.geometry}
        material={materials.Wolf3D_Eye}
        skeleton={nodes.EyeRight.skeleton}
        morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
        morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
      />
      <skinnedMesh
        name="Wolf3D_Head"
        geometry={nodes.Wolf3D_Head.geometry}
        material={materials.Wolf3D_Skin}
        skeleton={nodes.Wolf3D_Head.skeleton}
        morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
        morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
      />
      <skinnedMesh
        name="Wolf3D_Teeth"
        geometry={nodes.Wolf3D_Teeth.geometry}
        material={materials.Wolf3D_Teeth}
        skeleton={nodes.Wolf3D_Teeth.skeleton}
        morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
        morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Glasses.geometry}
        material={materials.Wolf3D_Glasses}
        skeleton={nodes.Wolf3D_Glasses.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Headwear.geometry}
        material={materials.Wolf3D_Headwear}
        skeleton={nodes.Wolf3D_Headwear.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Body.geometry}
        material={materials.Wolf3D_Body}
        skeleton={nodes.Wolf3D_Body.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
        material={materials.Wolf3D_Outfit_Bottom}
        skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
        material={materials.Wolf3D_Outfit_Footwear}
        skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Top.geometry}
        material={materials.Wolf3D_Outfit_Top}
        skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
      />
    </group>
  );
}

useGLTF.preload("/models/66e41cf73403d6dd0ab4d8f7 (1).glb");
