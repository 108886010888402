import React from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";

const LoginPromptModal = ({ show, onClose }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  if (!show) return null; 

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          width: isMobile ? "90%" : "400px",
          backgroundColor: "white",
          borderRadius: 3,
          padding: 4,
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Session Expired
        </Typography>
        <Typography variant="body1" color="textSecondary" mb={3}>
          Your session has expired. Please log in again to continue.
        </Typography>
        <Button
          onClick={onClose}
          variant="contained"
          color="secondary"
          size="large"
          sx={{
            minWidth: 120,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            textTransform: "none",
          }}
        >
          Ok
        </Button>
      </Box>
    </Box>
  );
};

export default LoginPromptModal;
