import React from "react";
import { useSpeechRecognitionContext } from "../hooks/useSpeechToText";

const VoiceInput = () => {
  const {
    isListening,
    startListening,
    stopListening,
    isSpeaking,
    stopAvatarSpeaking,
  } = useSpeechRecognitionContext();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "10px",
        paddingRight: "10px",
        backgroundColor: "black",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <button
          style={{
            backgroundColor: isListening
              ? "#d62d20"
              : isSpeaking
              ? "#008744"
              : "#008744",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            cursor: isSpeaking ? "not-allowed" : "pointer",
            transition: "background-color 0.3s ease",
            height: "50px",
            fontSize: "18px",
            opacity: isSpeaking ? 0.5 : 1,
          }}
          onClick={!isListening && !isSpeaking ? startListening : stopListening}
          disabled={isSpeaking}
        >
          {isListening ? "Disconnect" : "Let's start conversation"}
        </button>
        {isSpeaking && (
          <button
            style={{
              backgroundColor: "#f39c12",
              color: "white",
              padding: "8px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              height: "50px",
              fontSize: "16px",
            }}
            onClick={stopAvatarSpeaking}
          >
            Didn't like the response?
          </button>
        )}
      </div>
    </div>
  );
};

export default VoiceInput;
