import { Environment, useTexture } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { Avatar1 } from "./Avatar1";
import { useMediaQuery } from "@mui/material";

export const Experience = () => {
  const texture = useTexture("textures/images.jpg");
  const viewport = useThree((state) => state.viewport);

  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(min-width:768px) and (max-width:1199px)");

  const avatarProps = {
    position: isMobile
      ? [0, -8, 3]
      : isTablet
      ? [0, -8, 3]
      : [0, -8, 3], 
    scale: isMobile ? 4.5 : isTablet ? 4.5 :4.5,
  };

  return (
    <>
      <Avatar1 position={avatarProps.position} scale={avatarProps.scale} />
      <Environment preset="sunset" />
      <mesh>
        <planeGeometry args={[viewport.width, viewport.height]} />
        <meshBasicMaterial map={texture} />
      </mesh>
    </>
  );
};
