// React imports
import React from "react";
import axios from "axios";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LogoutButton = ({ onLogout }) => {
  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/logout`);

      // Clear the token from localStorage
      localStorage.removeItem("token");

      onLogout();
      toast.success("Logout successful!");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <Button
      variant="contained"
      color="error" 
      onClick={handleLogout}
      sx={{
        padding: "10px 20px",
        borderRadius: "5px",
        height: "40px",
        fontSize: "18px",
        transition: "background-color 0.3s ease",
        textTransform:"none"
      }}
    >
      Logout
    </Button>
  );
};

export default LogoutButton;
