import VoiceInput from "./components/index";
import { Canvas } from "@react-three/fiber";
import { Experience } from "./components/Experience";
import SpeechRecognitionProvider from "./hooks/useSpeechToText";
import React, { useState, useEffect } from "react";
import LoginPage from "./components/LoginPage";
import LogoutButton from "./components/LogoutButton";
import { ToastContainer } from "react-toastify";
import { Box } from "@mui/material";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Checking if token exists in localStorage
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <Box>
      <ToastContainer />
      <SpeechRecognitionProvider>
        {!isLoggedIn ? (
          <div>
            <LoginPage onLogin={handleLogin} />
          </div>
        ) : (
          <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", width :"90%", margin:"auto" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                pt: "30px", 
                pb: "15px",
                overflow:"hidden"  
              }}
            >
              <LogoutButton onLogout={handleLogout} />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "15px",
              }}
            >
              <Canvas
                style={{
                  height: "80vh",
                  width: "90vw",
                }}
                shadows
                camera={{ position: [0, 0, 8], fov: 42 }}
              >
                <color attach="background" args={["#ececec"]} />
                <Experience />
              </Canvas>
            </Box>
          </Box>
        )}
        <Box>{isLoggedIn && <VoiceInput />}</Box>
      </SpeechRecognitionProvider>
    </Box>
  );
}

export default App;