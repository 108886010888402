const visemeMap = {
  // Consonants
  p: ["mouthClose", "mouthPucker"],
  b: ["mouthClose", "mouthPucker"],
  m: ["mouthClose", "mouthPucker"],
  f: ["mouthPucker", "mouthLowerDownLeft", "mouthLowerDownRight"],
  v: ["mouthPucker", "mouthLowerDownLeft", "mouthLowerDownRight"],
  th: ["tongueOut", "mouthClose"],
  t: ["mouthClose", "tongueOut"],
  d: ["mouthClose", "tongueOut"],
  n: ["mouthClose", "tongueOut"],
  k: ["mouthClose", "tongueOut"],
  g: ["mouthClose", "tongueOut"],
  ng: ["mouthClose", "tongueOut"],
  ch: ["mouthClose", "mouthFunnel"],
  j: ["mouthClose", "mouthFunnel"],
  sh: ["mouthFunnel"],
  s: ["mouthClose", "mouthFunnel"],
  z: ["mouthClose", "mouthFunnel"],
  l: ["tongueOut"],
  r: ["mouthPucker"],
  // Vowels
  a: ["mouthOpen", "jawOpen", "tongueDown"],
  e: ["mouthSmileLeft", "mouthSmileRight", "jawOpen"],
  i: ["mouthSmileLeft", "mouthSmileRight", "mouthOpen", "tongueUp"],
  o: ["mouthFunnel", "jawOpen", "tongueDown"],
  u: ["mouthPucker", "mouthOpen", "tongueBack"],
  // Silence
  sil: ["mouthClose"],
  // Numbers
  0: ["mouthFunnel", "jawOpen"],
  1: ["mouthClose", "tongueOut"],
  2: ["mouthClose", "tongueOut"],
  3: ["mouthSmileLeft", "mouthSmileRight", "mouthOpen"],
  4: ["mouthClose", "mouthFunnel"],
  5: ["mouthClose", "mouthFunnel"],
  6: ["mouthClose", "mouthFunnel"],
  7: ["mouthClose", "tongueOut"],
  8: ["mouthClose", "mouthPucker"],
  9: ["mouthClose", "mouthPucker"],
  // Special characters
  "!": ["mouthOpen", "jawOpen"],
  "?": ["mouthOpen", "jawOpen"],
  ".": ["mouthClose"],
  ",": ["mouthClose"],
  "-": ["mouthClose"],
  _: ["mouthClose"],
  ":": ["mouthClose"],
  ";": ["mouthClose"],
  "(": ["mouthPucker"],
  ")": ["mouthPucker"],
  "[": ["mouthPucker"],
  "]": ["mouthPucker"],
  "{": ["mouthPucker"],
  "}": ["mouthPucker"],
  "<": ["mouthPucker"],
  ">": ["mouthPucker"],
  "/": ["mouthClose", "tongueOut"],
  "\\": ["mouthClose", "tongueOut"],
  "|": ["mouthClose", "tongueOut"],
  "@": ["mouthFunnel"],
  "#": ["mouthClose", "tongueOut"],
  "$": ["mouthClose", "mouthFunnel"],
  "%": ["mouthClose", "mouthFunnel"],
  "^": ["mouthClose", "tongueOut"],
  "&": ["mouthClose", "tongueOut"],
  "*": ["mouthClose", "tongueOut"],
  "+": ["mouthClose", "tongueOut"],
  "=": ["mouthClose"],
  "~": ["mouthPucker"],
  "`": ["mouthClose"],
  "'": ["mouthClose"],
  '"': ["mouthClose"],
  // Emotions and expressions
  happy: [
    "mouthSmileLeft",
    "mouthSmileRight",
    "eyeSquintLeft",
    "eyeSquintRight",
    "cheekSquintLeft",
    "cheekSquintRight",
  ],
  sad: [
    "mouthFrownLeft",
    "mouthFrownRight",
    "browInnerUp",
    "browDownLeft",
    "browDownRight",
  ],
  angry: [
    "browDownLeft",
    "browDownRight",
    "noseSneerLeft",
    "noseSneerRight",
    "mouthFrownLeft",
    "mouthFrownRight",
  ],
  surprised: [
    "mouthOpen",
    "jawOpen",
    "browInnerUp",
    "eyeWideLeft",
    "eyeWideRight",
  ],
  disgusted: [
    "noseSneerLeft",
    "noseSneerRight",
    "mouthLeft",
    "mouthRight",
    "browDownLeft",
    "browDownRight",
  ],
  fearful: [
    "browInnerUp",
    "eyeWideLeft",
    "eyeWideRight",
    "mouthOpen",
    "jawOpen",
  ],
  neutral: ["mouthClose"],
  // Additional facial movements
  blinking: ["eyeBlinkLeft", "eyeBlinkRight"],
  winking: ["eyeBlinkLeft"],
  squinting: ["eyeSquintLeft", "eyeSquintRight"],
  browRaising: ["browOuterUpLeft", "browOuterUpRight"],
  browLowering: ["browDownLeft", "browDownRight"],
  mouthCornerPull: ["mouthLeft", "mouthRight"],
  jawForward: ["jawForward"],
  jawLeft: ["jawLeft"],
  jawRight: ["jawRight"],
  neckStretch: ["neckStretch"],
  // Tongue movements
  tongueOut: ["tongueOut"],
  tongueUp: ["tongueUp"],
  tongueDown: ["tongueDown"],
  tongueLeft: ["tongueLeft"],
  tongueRight: ["tongueRight"],
  tongueRoll: ["tongueRoll"],
  tongueBendDown: ["tongueBendDown"],
  tongueCurlUp: ["tongueCurlUp"],
  // Lip movements
  lipPucker: ["mouthPucker"],
  lipFunnel: ["mouthFunnel"],
  lipSuck: ["mouthShrugUpper", "mouthShrugLower"],
  lipPress: ["mouthPress"],
  lipStretchLeft: ["mouthStretchLeft"],
  lipStretchRight: ["mouthStretchRight"],
  lipCornerDepressLeft: ["mouthFrownLeft"],
  lipCornerDepressRight: ["mouthFrownRight"],
  lipTighten: ["mouthRollLower", "mouthRollUpper"],
  // Cheek movements
  cheekPuff: ["cheekPuff"],
  cheekSuck: ["cheekSuck"],
  cheekSquint: ["cheekSquintLeft", "cheekSquintRight"],
  // Nose movements
  noseSneer: ["noseSneerLeft", "noseSneerRight"],
  nostrilFlare: ["noseSneerLeft", "noseSneerRight"],
  // Default for any other character
  default: ["mouthClose"],
};

export default visemeMap;
