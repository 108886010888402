import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
  useMemo,
} from "react";
import LoginPromptModal from "../../components/Modal";

const SpeechRecognitionContext = createContext();

const SpeechRecognitionProvider = ({ children }) => {
  const recognition = useMemo(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    return new SpeechRecognition();
  }, []);

  const [text, setText] = useState("");
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [responseData, setResponseData] = useState("");
  const audioContextRef = useRef(null);
  const audioSourceRef = useRef(null);
  const [cues, setCues] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const onResult = async (event) => {
    const current = event.resultIndex;
    const newText = event.results[current][0].transcript;
    const mobileRepeatBug =
      current === 1 && newText === event.results[0][0].transcript;

    if (!mobileRepeatBug) {
      setText((prevText) => prevText + " " + newText);
    }
  };

  const processText = async () => {
    if (text.trim() === "") return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/chat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ prompt: text }),
        }
      );

      if (response.status === 401) {
        setShowModal(true);
        return;
      }

      const responseData = await response.json();
      setResponseData(responseData.data);
      setText("");
    } catch (error) {
      console.log("Error calling backend API:", error);
    }
  };

  useEffect(() => {
    if (!recognition) return;

    recognition.continuous = true;
    recognition.interimResults = false;
    recognition.onresult = onResult;
    recognitionRef.current = recognition;

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        recognitionRef.current = null;
      }
    };
  }, [recognition]);

  useEffect(() => {
    if (isListening && !isSpeaking) {
      const timer = setTimeout(() => {
        processText();
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [text, isListening, isSpeaking]);

  const closeModal = () => {
    setShowModal(false);
  };

  const startListening = () => {
    setIsListening(true);
    recognition.start();
  };

  const stopListening = () => {
    setIsListening(false);
    recognition.stop();
    processText();
  };

  const stopAvatarSpeaking = () => {
    if (audioSourceRef.current) {
      audioSourceRef.current.stop();
      audioSourceRef.current.disconnect();
      audioSourceRef.current = null;
    }
    if (audioContextRef.current) {
      audioContextRef.current.suspend();
    }
    setIsSpeaking(false);
    setResponseData("");
    setCues([]);
  };

  const initializeAudioContext = () => {
    if (
      !audioContextRef.current ||
      audioContextRef.current.state === "closed"
    ) {
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
    }
    if (audioContextRef.current.state === "suspended") {
      audioContextRef.current.resume();
    }
  };

  return (
    <SpeechRecognitionContext.Provider
      value={{
        text,
        isListening,
        startListening,
        stopListening,
        hasRecognitionSupport: !!recognition,
        isSpeaking,
        setIsSpeaking,
        setIsListening,
        responseData,
        setResponseData,
        stopAvatarSpeaking,
        audioContextRef,
        audioSourceRef,
        setCues,
        cues,
        showModal,
        closeModal,
        initializeAudioContext,
      }}
    >
      {children}
      <LoginPromptModal show={showModal} onClose={closeModal}>
        <h2>Session Expired</h2>
        <p>Your session has expired. Please log in again.</p>
      </LoginPromptModal>
    </SpeechRecognitionContext.Provider>
  );
};

export const useSpeechRecognitionContext = () => {
  return useContext(SpeechRecognitionContext);
};

export default SpeechRecognitionProvider;
